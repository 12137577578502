import {
  AppShell as MantineAppShell,
  Box,
  Burger,
  Container,
  createStyles,
  Group,
  Header as MantineHeader,
  Navbar as MantineNavbar,
} from '@mantine/core';
import LogoDark from 'assets/logo/qwiz-dark.svg';
import LogoWhite from 'assets/logo/qwiz-white.svg';
import { NavbarDivider } from 'components/Nav/NavbarDivider';
import { NavbarHeader } from 'components/Nav/NavbarHeader';
import { NavbarList } from 'components/Nav/NavbarList';
import { NavbarUserMenu } from 'components/Nav/NavbarUser/NavbarUserMenu';
import NavSearchItem from 'components/Nav/NavSearchItem';
import { ThemeToggle } from 'components/UI/ThemeToggle';
import { ThinScrollArea } from 'components/UI/ThinScrollArea';
import { useCurrentUser } from 'hooks/api/users';
import { useBreakpoints } from 'hooks/breakpoints';
import { useAppColorscheme } from 'hooks/colorscheme';
import { useNavItems } from 'hooks/use-nav-items';
import config from 'lib/config';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { paths } from 'paths';
import { MagnifyingGlass } from 'phosphor-react';
import { useState } from 'react';

const useStyles = createStyles(() => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    header: {
      position: 'relative',
    },
  };
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AppShell = ({ children }: { children: any }) => {
  const { classes } = useStyles();

  const router = useRouter();
  const { isAuthenticated, isLoading, isSessionLoading } = useCurrentUser();
  const { isDark } = useAppColorscheme();
  const { matches } = useBreakpoints();
  const { items, iconProps } = useNavItems();

  const [opened, setOpened] = useState(false);
  const toggleNavbar = () => setOpened((prev) => !prev);

  const closeNav = () => setOpened(false);

  const logo = isDark ? LogoWhite : LogoDark;
  const Navbar = (
    <MantineNavbar
      hiddenBreakpoint="sm"
      hidden={!opened}
      p="md"
      width={{ base: '100%', sm: 260, md: 260, lg: 300, xl: 320 }}
      // fixed
    >
      {!matches.max.sm && (
        <MantineNavbar.Section>
          <NavbarHeader />
        </MantineNavbar.Section>
      )}
      <MantineNavbar.Section mt={8}>
        {/* <NavSearchItem
          icon={<MagnifyingGlass {...iconProps} />}
          onSelect={closeNav}
        /> */}
      </MantineNavbar.Section>
      <MantineNavbar.Section
        grow
        component={ThinScrollArea}
        mt={16}
        mb={12}
        ml={-16}
        mr={-16}
        pl={16}
        pr={16}
      >
        <NavbarList items={items} onSelect={closeNav} />
      </MantineNavbar.Section>
      <>
        <NavbarDivider />
        <MantineNavbar.Section mt={12}>
          {isAuthenticated || isLoading || isSessionLoading ? (
            <NavbarUserMenu.Account onSelect={closeNav} />
          ) : (
            <NavbarUserMenu.Guest />
          )}
        </MantineNavbar.Section>
      </>
    </MantineNavbar>
  );

  // TODO: for now, Navbar and Header cant be custom components for Mantine to do its magic
  //  find a way to extract them into their own components
  const Header = (
    <MantineHeader
      fixed={false}
      height={60}
      p="sm"
      px={20}
      className={classes.header}
    >
      <Group
        align="center"
        position="apart"
        sx={(t) => ({ width: '100%', height: '100%' })}
      >
        <Burger opened={opened} onClick={toggleNavbar} size="sm" />
        <Image
          onClick={() => router.push(config.landingPageUrl || paths.home())}
          style={{ cursor: 'pointer' }}
          src={logo}
          alt="logo"
          objectFit="contain"
          className="auth-logo"
          width={36}
          height={36}
        />
        {/* <ThemeToggle /> */}
      </Group>
    </MantineHeader>
  );

  return (
    <MantineAppShell
      className={classes.root}
      navbarOffsetBreakpoint="sm"
      navbar={Navbar}
      // fixed
      sx={(t) => ({
        backgroundColor: isDark ? t.colors.dark[8] : t.colors.gray[0],
        minHeight: '100vh',
      })}
      header={(matches.max.sm && Header) || undefined}
      styles={() => {
        return {
          main: { paddingTop: 0 },
          body: { overflow: 'auto' },
        };
      }}
    >
      <Container
        fluid
        p={0}
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          width: '100%',
        }}
      >
        {children}
      </Container>
    </MantineAppShell>
  );
};
